import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppsComponent } from './views/apps/apps.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultLayoutComponent } from './containers';
import { DefaultfrontLayoutComponent } from './frontcontainers';
import { LoginComponent } from './views/login/login.component';
import { IndexComponent } from './views/index/index.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { CreateNewForgotPasswordComponent } from './views/create-new-forgot-password/create-new-forgot-password.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    DefaultfrontLayoutComponent,
    LoginComponent,
    AppsComponent,
     IndexComponent,
    ForgotPasswordComponent,
    CreateNewForgotPasswordComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    CKEditorModule,
    RouterModule.forRoot([
      {path:'',component:IndexComponent},
    ]),
    // RouterModule.forRoot([
    //   {path:'',component:LoginComponent},
    // ]),
   
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
