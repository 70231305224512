import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import { Meta } from '@angular/platform-browser';
import { global } from '../../global';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  styleUrls: [
    './../../../assets/FrontEnd/css/bootstrap.min.css',
    './../../../assets/FrontEnd/css/owl.theme.default.min.css',
    './../../../assets/FrontEnd/css/owl.carousel.min.css',
    './../../../assets/FrontEnd/css/swiper.min.css',
    './../../../assets/FrontEnd/css/style_front.css',
    './../../../assets/css/flaticon.css',
    './../../../assets/css/animate.css',
  ],
  selector: 'app-index',
  templateUrl: './index.component.html',
  encapsulation: ViewEncapsulation.None

})
export class IndexComponent implements OnInit {
  PlanDataBuyer: any;
  PlanIdBuyer: any;
  PlanPriceBuyer: string;
  PlanDataSeller: any;
  PlanIdSeller: any;
  PlanPriceSeller: string;
  PlanData: Object;

  submitted = false;
  Newletter = "";
  itemdata: any;
  result: any;
  guest_arr: any;
  Expire_OfferDate_Format:any;
  private NewsletterForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService, private meta: Meta, private http: HttpClient) {
    this.meta.addTags([
      { name: 'description', content: 'Lab Grown Diamond Trade provide a global marketplace to buy and sell diamonds to licensed retail and wholesale buyers across the world.' },
      { name: 'title', content: 'Lab Grown Diamond Marketplace for Synthetic Diamond Merchant' }
    ]);
    const author = this.meta.getTag('name=description');

    this.NewsletterForm = this.formBuilder.group({
      Newletter: ['', Validators.required],
    });
    // if(document.cookie.indexOf('IsGuest=Yes') != -1){
    //   localStorage.clear();
    // }

  }
  get f() { return this.NewsletterForm.controls; }
  ngOnInit() {
    this.Expire_OfferDate_Format = 'Code valid till 31st december  2023';
    this.loadScript('./../../../assets/FrontEnd/js/custome.js');
    $ (window).ready (function () {
      var d = new Date();
      var month=d.getMonth()+1;
      var CurrentDate = d.getFullYear()+'-'+month+'-'+d.getDate();
      
      var Expire_OfferDate = '2023-12-31';
      if(new Date(CurrentDate) <= new Date(Expire_OfferDate))
      {
        setTimeout (function () {
          $('#buyer-popup').modal ("show")
        }, 3000)
      } 
      
    })
    var Form = new FormData();
    var link = global.service_url + global.GetMemberPlan;
    Form.append('KEY', global.key);
    this.http.post(link, Form).subscribe(data => {
      this.PlanData = data;
      this.GetPlanPriceBuyer('0', '1', this.PlanData);
      this.GetPlanPriceSeller('1', '1', this.PlanData);
    });
    var link1 = global.service_url + global.Get_Newslatter_IP;
    var form = new FormData();
    form.append("Email_Type", '6');
    form.append('KEY', global.key);
    this.http.post(link1, form).subscribe(data => {
      this.result = data;
      // if (this.result.status == "1") {
      //   $('#newslatter-modal').modal('hide');
      // } else if (this.result.status == "0") {
      //   if (document.cookie.indexOf('Is_Newslatter_Show=Yes') != -1) {
      //     $('#newslatter-modal').modal('hide');
      //   } else {
      //     setTimeout(() => {
      //       $('#newslatter-modal').modal('show');
      //     }, 9999);
      //   }
      // }
    });
  }
 
  Is_Newslatter_Show() {
    $('#newslatter-modal').addClass('zoomOutDown');
    setTimeout(function () {
      $('#newslatter-modal').modal('hide');
    }, 700);

    var hours = 2;
    var now = new Date();
    var exp = new Date(now.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = 'Is_Newslatter_Show=Yes;expires=' + exp.toUTCString();
  }


  Newsletter() {
    $("#loader").show();
    this.submitted = true;
    if (this.Newletter != '') {
      var link = global.service_url + global.Subscribe_Add;
      var form = new FormData();
      form.append("Email", this.Newletter);
      form.append("Email_Type", '6');
      form.append('KEY', global.key);
      this.http.post(link, form).subscribe(data => {
        this.result = data;
        if (this.result.status == "1") {
          $("#loader").hide();
          this.toastr.success(this.result.message);
          $('#newslatter-modal').addClass('zoomOutDown');
          setTimeout(function () {
            $('#newslatter-modal').modal('hide');
          }, 2000);
        } else if (this.result.status == "0") {
          $("#loader").hide();
          this.toastr.error(this.result.message);
        }
      });
    } else {
      $("#loader").hide();
      this.toastr.error("Please enter email.");
    }

  }
  public loadScript(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  SignUpPage(Type) {
    localStorage.setItem('is_memberType', Type);
    this.router.navigate(['./register']);
  }
  GetPlanPriceBuyer(TYPE, TIME, PlanData) {
    if (TIME == '1' && TYPE == '0') {
      if (PlanData.data.BuyerOneMonth != null) {
        // var time = '/Month';
        var time = '';
        var sign = '$ ';
        this.PlanPriceBuyer = sign + PlanData.data.BuyerOneMonth.Price + time;
      } else {
        var time = 'Free';
        var sign = '';
        this.PlanPriceBuyer = time;
      }

    } else if (TIME == '12' && TYPE == '0') {
      if (PlanData.data.BuyerOneYear != null) {
        // var time = '/Month';
        var time = '';
        var sign = '$ ';
        this.PlanPriceBuyer = sign + PlanData.data.BuyerOneYear.Price + time;
      } else {
        var time = 'Free';
        var sign = '';
        this.PlanPriceBuyer = time;
      }

    }

  }
  GetPlanPriceSeller(TYPE, TIME, PlanData) {
    if (TIME == '1' && TYPE == '1') {
      if (PlanData.data.SellerOneMonth != null) {
        // var time = '/Month';
        var time = '';
        var sign = '$ ';
        this.PlanPriceSeller = sign + PlanData.data.SellerOneMonth.Price + time;
      } else {
        var time = 'Free';
        var sign = '';
        this.PlanPriceSeller = time;
      }

    } else if (TIME == '12' && TYPE == '1') {
      if (PlanData.data.SellerOneYear != null) {
        // var time = '/Year';
        var time = '';
        var sign = '$ ';
        this.PlanPriceSeller = sign + PlanData.data.SellerOneYear.Price + time;
      } else {
        var time = 'Free';
        var sign = '';
        this.PlanPriceSeller = time;
      }

    }

  }
  SearchDiamond() {
    // if (UserType == 6) {
    // var guest_obj=[];
    var guest_arr = {};
    guest_arr['User_Id'] = '';
    guest_arr['User_Type'] = '6';
    guest_arr['First_Name'] = 'Guest';
    guest_arr['user_image'] = '';
    guest_arr['Member_Id'] = '';
    guest_arr['Is_Active'] = '1';
    // guest_obj.push(guest_arr);
    var loc_login = localStorage.getItem('loc_login');
    if (loc_login === null) {
      localStorage.setItem('loc_login', JSON.stringify(guest_arr));
      localStorage.setItem('Current_Tab', '1');
    }
    var hours = 2;
    var now = new Date();
    var exp = new Date(now.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = 'IsGuest=Yes; expires=' + exp.toUTCString();
    this.router.navigate(['/search-lgd']);
    // }

  }
}


