import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import * as $ from 'jquery';
@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './apps.component.html',
})
export class AppsComponent implements OnInit {

  constructor()
  {

    $("#loader").show();
    var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    var isAndroid = /Android/i.test(navigator.userAgent);

    if (isIOS) {
      window.location.href = 'https://itunes.apple.com/us/app/lgd-trade/id1448347201?mt=8&ign-mpt=uo%3D4';
    }else if(isAndroid){
      window.location.href = 'https://play.google.com/store/apps/details?id=diamond.lgd.labgrown';
    } else {
      window.location.href = '/';
    }
    $("#loader").hide();
  }

  ngOnInit() {}
}
