export var global = {

    // controller_service_Url:'http://localhost/LGDTrade/Welcome/',
    // service_url: 'http://localhost/LGDTrade/CnV0dW5qc2hlbGFkaXlh/',

  // base_url : 'http://lgd.jorjoto.in/',
  // controller_service_Url:'http://lgd.jorjoto.in/Welcome/',
  // service_url: 'http://lgd.jorjoto.in/CnV0dW5qc2hlbGFkaXlh/',
  // get_image_Url:'http://lgd.jorjoto.in/assets/uploads/',
  // siteURL: 'http://lgd.jorjoto.in/',

    // base_url : 'https://www.lgdtrade.com/',
    // controller_service_Url:'https://app.lgdtrade.com/Welcome/',
    // service_url: 'https://app.lgdtrade.com/CnV0dW5qc2hlbGFkaXlh/',
    // get_image_Url:'https://app.lgdtrade.com/assets/uploads/',
    // siteURL: 'https://app.lgdtrade.com/',



   // Live Api
    base_url : 'https://www.lgdtrade.com/',
    controller_service_Url:'https://app.lgdtrade.com/V4Web/Welcome/',
    service_url: 'https://app.lgdtrade.com/V4Web/CnV0dW5qc2hlbGFkaXlh/',
    get_image_Url:'https://app.lgdtrade.com/assets/uploads/',  
    siteURL: 'https://app.lgdtrade.com/',  
      
    // Local Api
    // base_url : 'https://dev.lgdtrade.com/',
    // controller_service_Url:'https://appdev.lgdtrade.com/V3Web/Welcome/',
    // service_url: 'https://appdev.lgdtrade.com/V3Web/CnV0dW5qc2hlbGFkaXlh/',
    // get_image_Url:'https://appdev.lgdtrade.com/assets/uploads/',
    // siteURL: 'https://appdev.lgdtrade.com/',
    
    ChangePassword: 'ChangePassword',
    key: 'Yn4ZtQCOqRG2JU3abeiXVplTjvKxr0H7k8gymoEwW1LSAs69fzNP5DucBFhIMd',
    Forgottpassword: 'Forgottpassword',
    ForgotPassword_verifyEmail: 'ForgotPassword_verifyEmail',
    UserLogin: 'UserLogin',
    Add_MemberMaster: 'Add_MemberMaster',
    Select_Country: 'Select_Country',
    Add_UserMaster: 'Add_UserMaster',
    Add_UserMasterr: 'Add_UserMasterr',
    GetParameterValue: 'GetParameterValue',
    GetParameterType: 'GetParameterType',
    List_UserMaster: 'List_UserMaster',
    List_PlanMaster : 'List_PlanMaster',
    Email_Exist: 'Email_Exist',
    List_MemberMaster: 'List_MemberMaster',
    Status_Change: 'Status_Change',
    Select_UserMaster: 'Select_UserMaster',
    Select_MemberMaster: 'Select_MemberMaster',
    UserMaster_Edit: 'UserMaster_Edit',
    MemberMaster_Edit: 'MemberMaster_Edit',
    Edit_Email_Exist: 'Edit_Email_Exist',
    Edit_MemberEmail_Exist: 'Edit_MemberEmail_Exist',
    Select_Member_Code: 'Select_Member_Code',
    plan_status_change : 'Status',
    plan_edit : 'PlanEdit',
    plan_add : 'Planadd',
    List_ParameterMaster : 'List_ParameterMaster',
    parameterType : 'parameterType',
    StatusParameter : 'StatusParameter',
    Parameteredit : 'Parameteredit',
    parameterAdd : 'parameterAdd',
    StoneDetails: 'StoneDetails',
    list_InquiryMaster : 'list_InquiryMaster',
    Search_resultList : 'Search_resultList',
    SaveSearch: 'SaveSearch',
    RecentSearch: 'RecentSearch',
    MySaveSearch: 'MySaveSearch',
    MySaveSearchDelete: 'MySaveSearchDelete',
    RecentSearchDelete: 'RecentSearchDelete',
    GetParameterValue1: 'GetParameterValue',
    GetParameterType1: 'GetParameterType',
    CountSearch: "CountSearch",
    PingRecord : 'PingRecord',
    MultistockImageUpload : 'MultistockImageUpload',
    List_StockSummary: 'List_StockSummary',
    Confoirm_Stock_Upload: 'Confoirm_Stock_Upload',
    Download_SuccessStock_Excel: 'Download_SuccessStock_Excel',
    MyProfile : 'MyProfile',
    list_Profile_User : 'list_Profile_User',
    get_user_profile : 'user_profile',
    MemberUpdate : 'MemberUpdate',
    AboutCompany: 'AboutCompany',
    List_WishlistMaster : 'List_WishlistMaster',
    AddToWishlist : 'AddToWishlist',
    AddToEnquiry : 'AddToEnquiry',
    ModifySearch: 'ModifySearch',
    StoneDetails1: "StoneDetails",
    Confirm_Stock_Upload: 'Confirm_Stock_Upload',
    List_Single_StoneSummary: 'List_Single_StoneSummary',
    MultistockImageDelete: 'MultistockImageDelete',
    Confirm_Stock_Delete: 'Confirm_Stock_Delete',
    company_profile : "company_profile",
    list_Enquiries_Master : 'list_Enquiries_Master',
    MemberDirectory: 'MemberDirectory',
    Select_BussinessType: 'Select_BussinessType',
    Download_StoneExcel : 'Download_StoneExcel',
    Send_mail_main : 'Send_mail_main',
    EnquieryDone : 'EnquieryDone',
    AddRatings : "AddRatings",
    AddToSnooze : 'AddToSnooze',
    Single_Stock_Upload: 'Single_Stock_Upload',
    Single_Stock_Update: 'Single_Stock_Update',
    Upload_History: 'Upload_History',
    GetFilterParameterValue: 'GetFilterParameterValue',
    Countdaimond : 'Countdaimond',
    list_inventory_list: 'list_inventory_list',
    CountdaimondInventory : 'CountdaimondInventory',
    ContactUs_Add : 'ContactUs_Add',
    UserMasterNil_Edit : 'UserMasterNil_Edit',
    filterName : 'filterName',
    Check_Expire_Time: 'Check_Expire_Time',
    Subscribe_Add : 'Subscribe_Add',
    DeleteWishList : 'DeleteWishList',
    DeleteEnquieryList : 'DeleteEnquieryList',
    Select_LGD_Settings_Background: 'Select_LGD_Settings_Background',
    Recent_SaveSearchCount: 'Recent_SaveSearchCount',
    getRatingUserList : 'getRatingUserList',
    GetMemberPlan: 'GetMemberPlan',
    Select_CurrentUser_Rating: 'Select_CurrentUser_Rating',
    DeleteInventory : 'DeleteInventory',
    DiamondImageUpload:'DiamondImageUpload',
    DiamondCertificateUpload:'DiamondCertificateUpload',
    Media_Upload_History:'Media_Upload_History',
    getNotificationList : 'getNotificationList',
    NotificationDetail : 'NotificationDetail',
    notificationCount : 'notificationCount',
    notiReadDone : 'notiReadDone',
    NotificationDelete : 'NotificationDelete',
    TransactionDetail :'TransactionDetail',
    RenewSubscription :'RenewSubscription',
    Edit_MemberLogo :'Edit_MemberLogo',
    CheckPaymentExpire:'CheckPaymentExpire',
    GetMemberPalnHistory:'GetMemberPalnHistory',
    Payment_History:'Payment_History',
    Get_All_Notification:'Get_All_Notification',
    Get_Valid_Media_Image:'Get_Valid_Media_Image',
    Get_Valid_Media_Cer:'Get_Valid_Media_Cer',
    Media_Delete:'Media_Delete',
    History_Delete:'History_Delete',
    AddPlayerId:'AddPlayerId',
    ApplyCoupon:'ApplyCoupon',
    UnSubscribe:'UnSubscribe',
    Get_Newslatter_IP:'Get_Newslatter_IP',
    Add_Visiter_EnquiryMaster:'Add_Visiter_EnquiryMaster',
    Add_EnquiryPopup:'Add_EnquiryPopup',
    All_Media_Delete:'All_Media_Delete',
    Get_Dashboard_Member:'Get_Dashboard_Member',
    SaveGuestUser:'SaveGuestUser',
    check_authorized_user:'check_authorized_user',
    MultistockJewelleryUpload:'MultistockJewelleryUpload',
    Confirm_Jwellery_Stock_Upload:'Confirm_Jwellery_Stock_Upload',
    Jwellery_Material_Details:'Jwellery_Material_Details',
    List_JwelleryStockSummary:'List_JwelleryStockSummary',
    Jwellery_inventory_list:'Jwellery_inventory_list',
    DeleteJwelleryInventory:'DeleteJwelleryInventory',
    GetCategoryList:'GetCategoryList',
    AddJewelleryEnquiry:'AddJewelleryEnquiry',
    Jwellery_list_Enquiries_Master:'Jwellery_list_Enquiries_Master',
    DeleteJwelleryEnquiery:'DeleteJwelleryEnquiery',
    Jwellery_My_Leads_List:'Jwellery_My_Leads_List',
    Jwellery_Wishlist:'Jwellery_Wishlist',
    GetJwelleryDataById:'GetJwelleryDataById',
    JwelleryImageUpload:'JwelleryImageUpload',
    Get_Valid_Jwellery_Media_Video:'Get_Valid_Jwellery_Media_Video',
    Get_Valid_Jwellery_Media_Image:'Get_Valid_Jwellery_Media_Image',
    Jwellery_Media_Delete:'Jwellery_Media_Delete',
    Send_Mail_For_Jewellery:'Send_Mail_For_Jewellery',
    ContactLog:'ContactLog',
    CheckFTPRequest:'CheckFTPRequest',
    Send_FTP_Request:'Send_FTP_Request',


    // Rough Diamond Service

    GetRoughDiamondList: 'GetRoughDiamondList',
    Send_Mail_For_Rough:'Send_Mail_For_Rough',
    Rough_Wishlist:'Rough_Wishlist',
    GetRoughDataById:'GetRoughDataById',
    Rough_list_Enquiries_Master:'Rough_list_Enquiries_Master',
    Rough_My_Leads_List:'Rough_My_Leads_List',
    Rough_inventory_list:'Rough_inventory_list',
    DeleteRoughInventory:'DeleteRoughInventory',
    MultistockRoughUpload:'MultistockRoughUpload',
    Confirm_Rough_Stock_Upload:'Confirm_Rough_Stock_Upload',
    List_RoughStockSummary:'List_RoughStockSummary',
    AddRoughEnquiry:'AddRoughEnquiry',
};
