import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { global } from '../../global';
import * as $ from 'jquery';
declare global {
    interface Window { functions: any }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './defaultfront-layout.component.html',

})
export class DefaultfrontLayoutComponent implements OnInit {
  submitted = false;
  Newletter="";
  itemdata: any;
  result: any;
  private NewsletterForm: FormGroup;
  CurrentYear: number;

  constructor(private formBuilder: FormBuilder, titleService:Title, private http: HttpClient,private router: Router, private toastr: ToastrService)
  {
    if (document.cookie && document.cookie.indexOf('IsLogin=Yes') != -1 || document.cookie && document.cookie.indexOf('IsGuest=Yes') != -1) {
      // localStorage.clear();
    }else{
      localStorage.clear();
      sessionStorage.clear();
    }
    this.NewsletterForm = this.formBuilder.group({
      Newletter:['', Validators.required],
      });
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join(' ');
          title = title.split(",").join("");
          titleService.setTitle(title);
        }
      });
  }
  get f() { return this.NewsletterForm.controls; }
 
  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  ngOnInit() {
    var d = new Date();
   this.CurrentYear = d.getFullYear();
  }
  Newsletter(){
    $("#loader").show();
      this.submitted = true;
      if (this.Newletter != ''){
          var link = global.service_url + global.Subscribe_Add;
              var form = new FormData();
              form.append("Email", this.Newletter);
              form.append("Email_Type",'6');
              form.append('KEY', global.key);
              this.http.post(link, form).subscribe(data => {
                  this.result = data;
                  if (this.result.status == "1") {
                    $("#loader").hide();
                    this.toastr.success(this.result.message);
                    setTimeout(function () {
                      location.reload();
                    }, 2000);
                  } else if (this.result.status == "0") {
                    $("#loader").hide();
                    this.toastr.error(this.result.message);
                  }
                });
      }else{
        $("#loader").hide();
          this.toastr.error("Please enter email.");
        }
        
    }
}
