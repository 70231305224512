import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { global } from '../../global';
import {Meta, Title} from '@angular/platform-browser';

import * as $ from 'jquery';
import { IfStmt } from '@angular/compiler';
@Component({

  styleUrls: [
    './../../../assets/css/bootstrap.min.css',
    './../../../assets/css/select2.min.css',
    './../../../assets/css/jquery.dataTables.min.css',
    './../../../assets/css/MyCustome.css',
    './../../../assets/css/daterangepicker.css',
    './../../../assets/css/custom.css',
    './../../../assets/css/style_back.css',
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  private LoginForm: FormGroup;
  Email = "";
  Password = "";
  submitted = false;
  result: any;
  key: any;
  Login_BG : any;
  dashboard_MemberId:any;
  Dashboard_Url:any;
  constructor(private route: ActivatedRoute,private meta: Meta,titleService:Title,private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private toastr: ToastrService)
  {
    this.meta.addTags([
      { name: 'description', content: 'Login to your LGD Trade Account and get access to your purchase history and reserve diamonds for a new order and other LGD Trade features.' },
      { name: 'title', content: 'Login to your LGD Trade Account | Sign In' }
    ]);
    const author = this.meta.getTag('name=description');

    var Form = new FormData();
    var link = global.service_url + global.Select_LGD_Settings_Background;
    Form.append('Setting_Key','login');
    Form.append('KEY', global.key);
    this.http.post(link, Form).subscribe(data => {
      this.result = data;
      if (this.result.status == "1") {
        this.Login_BG=this.result.message;
        $('.login-left').css('background-image', 'url(' + this.Login_BG + ')');
      }else if (this.result.status == "0") {

      }
    });
    this.LoginForm = this.formBuilder.group({
      Email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      Password: ['', Validators.required]
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title = this.getTitle(router.routerState, router.routerState.root).join(' ');
        title = title.split(",").join("");
        titleService.setTitle(title); 
      }
    });
    // if(document.cookie.indexOf('IsGuest=Yes') != -1){
    //   localStorage.clear();
    // }

  }

  get f() { return this.LoginForm.controls; }

  ngOnInit() {
    if(this.route.snapshot.queryParams.NSAKIMNEMSIHD != undefined){
      this.Dashboard_Url= this.route.snapshot.queryParams.NSAKIMNEMSIHD;
      var dashboard_MemberId = this.route.snapshot.queryParamMap.get('NSAKIMNEMSIHD');
    }else if(this.route.snapshot.queryParams.NMADIMNEMSIHD != undefined){
      this.Dashboard_Url= this.route.snapshot.queryParams.NMADIMNEMSIHD;
      var dashboard_MemberId = this.route.snapshot.queryParamMap.get('NMADIMNEMSIHD');
    }
    if(this.Dashboard_Url != undefined){
      var dashForm = new FormData();
      dashForm.append('Member_Id', dashboard_MemberId);
      dashForm.append('KEY', global.key);
      var link = global.service_url + global.Get_Dashboard_Member;
      this.http.post(link, dashForm).subscribe(data => {
        this.result = data;
          if (this.result.status == "1") { 
          
            localStorage.setItem('loc_login', JSON.stringify(this.result.Login));
            sessionStorage.setItem('ses_login', JSON.stringify(this.result.Login));
            localStorage.setItem('Is_Expired',this.result.CheckPaymentExpire.Is_Expired);
            localStorage.setItem('Is_Payment_Alert', this.result.CheckPaymentExpire.Is_Show_Subscription_Alert);
            localStorage.setItem('Transaction_Info', JSON.stringify(this.result.CheckPaymentExpire.Transaction_Info));
            var hours=2;
            var now = new Date();
            var exp = new Date(now.getTime() + (hours*60*60*1000));
            document.cookie = 'IsLogin=Yes; expires='+exp.toUTCString();
          }
      });

      if(this.route.snapshot.queryParams.NSAKIMNEMSIHD != undefined){
          this.router.navigate(['/dashboard-stock-upload']);  
      }else if(this.route.snapshot.queryParams.NMADIMNEMSIHD != undefined){
          this.router.navigate(['/dashboard-media-upload']);  
      }
    }


    var loc_login = localStorage.getItem('loc_login');
    var logindate = JSON.parse(loc_login);
    if (loc_login != null && logindate.User_Type!='6') {
      this.router.navigate(['/search-lgd']);
    } else if (loc_login == null) {
    }
    $(window).resize(function() {
        $('.login-main').height($(window).height() - 0);
    });
    $(".toggle-password").click(function() {
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    $("#password").keyup(function(event) {
      if (event.keyCode === 13) {
        $("#loginbtn").click();
      }
    });
    $(window).trigger('resize');


    $(document).ready(function() {
      var formInputs = $('input[type="email"],input[type="password"]');
      $(formInputs).parent().children('p.formLabel').addClass('formTop');
      $('.formWrapper').addClass('darken-bg');
    });
  }
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  login() {
    this.toastr.clear();
    $("#loader").show();
    this.submitted = true;
    var loginform = new FormData();
    sessionStorage.removeItem("ses_verifyCode");

    if (this.Email != '' && this.Password != '') {
      loginform.append("Email", this.Email);
      loginform.append("Password", this.Password);
      loginform.append('KEY', global.key);
      var link = global.service_url + global.UserLogin;
      this.http.post(link, loginform)
        .subscribe(data => {
          
          this.result = data;
          if (this.result.status == "1") {
            $("#loader").hide();
            this.router.navigate(['/search-lgd']);
            localStorage.setItem('loc_login', JSON.stringify(this.result.Login));
            sessionStorage.setItem('ses_login', JSON.stringify(this.result.Login));
            localStorage.setItem('Is_Expired',this.result.CheckPaymentExpire.Is_Expired);
            localStorage.setItem('Is_Payment_Alert', this.result.CheckPaymentExpire.Is_Show_Subscription_Alert);
            localStorage.setItem('Transaction_Info', JSON.stringify(this.result.CheckPaymentExpire.Transaction_Info));
            localStorage.setItem('Current_Tab', '1'); 
            var hours=2;
            var now = new Date();
            var exp = new Date(now.getTime() + (hours*60*60*1000));
            document.cookie = 'IsLogin=Yes; expires='+exp.toUTCString();
          } else if (this.result.status == "0") {
            $("#loader").hide();
            this.toastr.error(this.result.message);
          }
        }
        );
    } else {
      $("#loader").hide();
      this.toastr.error('Please Enter Email Or Password.');
    }
  }
}
