import { global } from './../../global';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
declare var $: any;
declare global {
  interface Window { functions: any }
}
@Component({ 
  styleUrls: [
    './../../../assets/css/bootstrap.min.css',
    './../../../assets/css/select2.min.css',
    './../../../assets/css/jquery.dataTables.min.css',
    './../../../assets/css/MyCustome.css',
    './../../../assets/css/daterangepicker.css',
    './../../../assets/css/custom.css',
    './../../../assets/css/style_back.css',
    './../../../assets/css/flaticon.css',
    './../../../assets/css/br-default-theme.css',
  ],
  encapsulation: ViewEncapsulation.None,
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class DefaultLayoutComponent implements OnInit {
  //   public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  itemdata: any;
  Login_Name: any;
  Login_Image: any;
  SidePlanMaster = 'show-class';
  SideParameterMaster = 'show-class';
  SideInquiryMaster = 'show-class';
  SideWishliastMaster = 'show-class';
  SideMemberMaster = 'show-class';
  SideUserMaster = 'show-class';
  SideStockupload = 'show-class';
  SideMyaccountupload = 'show-class';
  SideEnquiryMaster = 'show-class';
  InventoryDisplay = 'show-class';
  NotiDesc: any;
  NotiTitle: any;
  NotiListes: any;
  ColorClass: any;
  DetailTitle: any;
  DetailDesc: any;
  DetailShortDesc: any;
  DetailImage: any;
  DetailVideo: any;
  DetailBtnTitle: any;
  DetailScreenNo: any;
  DetailTransfer: any;
  CurrentNotiPage: any;
  notificationCounts: any;
  pageNumber: any;
  result: any;
  @Output() notiFicationRecord = new EventEmitter();
  response: any;
  Screen_No: any;
  Is_Show_Detail: string;
  Is_Payment_Alert: string;
  Is_Expired: string;
  Transaction_Info: any; Radiovalue: any;
  PlanPrice: string;
  PlanAmount: number;
  PlanId: any;
  Time: any;
  PlanData: any;
  time: string;
  Is_MemberType: string;
  IS_SHOW ='0';
  Is_Model_Close=1;
  Remaining_Day: any;
  MemberPalnHistory: any;
  Remaining_Day_Noti:any;
  Remaining_Day_Noti_msg:any;
  UserType: any;

  private RenewForm: FormGroup; 
  package_type: any;
  // 
  Offer_Id: any;
  Offer_Amount: any;
  Promo_Code: any;
  error_msg: any;
  promo_status: any;
  Is_Cancel:any;
  Is_Tab: any;

  constructor(private formBuilder: FormBuilder,private router: Router, titleService: Title, private location: Location, private http: HttpClient, private toastr: ToastrService, public sanitizer: DomSanitizer) {
    
    if (document.cookie && document.cookie.indexOf('IsLogin=Yes') != -1 || document.cookie.indexOf('IsGuest=Yes') != -1) {

     } else {
      localStorage.clear();
      sessionStorage.clear();
    }
    this.RenewForm = this.formBuilder.group({
      Promo_Code: [''],
    });
    this.notiFicationRecord = new EventEmitter();
    this.DetailTitle = '';
    this.DetailDesc = '';
    this.DetailShortDesc = '';
    this.DetailImage = '';
    this.DetailVideo = '';
    this.DetailBtnTitle = '';
    this.DetailScreenNo = '';
    this.DetailTransfer = '';
    var ses_login = sessionStorage.getItem('ses_login');
    var loc_login = localStorage.getItem('loc_login');
    this.itemdata = JSON.parse(loc_login); 
// this.User_Type=this.itemdata.User_Type;
    // alert(loc_login);

    if (loc_login === null) {
      window.location.href = '/login';
    }
    this.UserType=this.itemdata.User_Type;
    if (this.itemdata.user_image != '' && this.itemdata.User_Type != '6') {
      this.Login_Image = global.get_image_Url + 'user_profile/' + this.itemdata.user_image;
    } else {
      this.Login_Image = '../assets/images/icn_no_user.png';
    }
    this.Login_Name = this.itemdata.First_Name;
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title = this.getTitle(router.routerState, router.routerState.root).join(' ');
        title = title.split(",").join("");
        titleService.setTitle(title);
      }
    });
  }
  get f() {
    return this.RenewForm.controls; }
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  ngOnInit() {

    this.Is_Tab = localStorage.getItem('Current_Tab');
    $('.header-nav-link').removeClass('current');
    if (this.Is_Tab == 1) {
      $('.center-diamond-tab').addClass('current');
      $('.LGD-Diamond-Tab').addClass('current');

      $('.LGD-Jewellery-Tab').removeClass('current');
      $('.LGD-Rough-Tab').removeClass('current');
      
    }else if (this.Is_Tab == 2) {
      $('.center-jewellery-tab').addClass('current');
      $('.LGD-Jewellery-Tab').addClass('current');

      $('.LGD-Diamond-Tab').removeClass('current');
      $('.LGD-Rough-Tab').removeClass('current');
    }else if (this.Is_Tab == 3) {
      $('.center-rough-tab').addClass('current');
      $('.LGD-Rough-Tab').addClass('current');

      $('.LGD-Diamond-Tab').removeClass('current');
      $('.LGD-Jewellery-Tab').removeClass('current');
      
    }
    
    
    var OneSignal = window['OneSignal'] || [];
  OneSignal.push(function () {
    OneSignal.init({
      appId: "b68484ec-13f8-48e3-8e4a-1bcb83ca7242",
    });
  });
  $(document).ready(function () {
    setTimeout(function () {
      checkNotificationSupport();
    }, 1000);
  });
  function checkNotificationSupport() {
    var ses_login = localStorage.getItem('loc_login');
    var logindate = JSON.parse(ses_login);
    var isPushSupported = OneSignal.isPushNotificationsSupported();
    var url= global.controller_service_Url;
    var link = global.controller_service_Url + global.AddPlayerId;
    if (isPushSupported) {
      // Push notifications are supported
      OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {

        if (isEnabled) {
          OneSignal.getUserId(function (userId) {
            $.ajax({
              type: "post",
              url: link ,
              data: {
                'player_id': userId,
                'User_Id':logindate.User_Id,
              },
              beforeSend: function () { },
              success: function (data) {
              }
            });
          });

        } else {
          OneSignal.showHttpPermissionRequest();
          console.log("Push notifications are not enabled yet.");
        }
      });

    } else {
      console.log("Push notifications are not supported.");
    }
  }


    const component = this;
    
    var loc_login = localStorage.getItem('loc_login');
    this.itemdata = JSON.parse(loc_login);
    if (loc_login === null) {
      // window.location.href = '/login';
      this.router.navigate(['/login']);
    }
    if (this.itemdata.User_Type == '4') {
      this.Is_MemberType = '0';
    } else if (this.itemdata.User_Type == '2') {
      this.Is_MemberType = '1';
    } else if (this.itemdata.User_Type == '3') {
      this.Is_MemberType = '1';
    } else if (this.itemdata.User_Type == '5') {
      this.Is_MemberType = '0';
    }
    if ((document.cookie && document.cookie.indexOf('Is_Main_Noti_Show'+this.itemdata.User_Id+'=Yes') != -1) || this.UserType=='6'){
      $("body").removeClass("main-notification-show");
    }

    if(this.itemdata.User_Id != '' || this.UserType!='6'){
      var NotiForm = new FormData();
      NotiForm.append('User_Id', this.itemdata.User_Id);
      NotiForm.append('KEY', global.key);
      var link = global.service_url + global.CheckPaymentExpire;
      this.http.post(link, NotiForm).subscribe(data => {
        this.result = data['data'];
        this.Check_Is_Expired(this.result.Remaining_Day);
        if(this.result.Remaining_Day <= 10){
          this.Remaining_Day_Noti=this.result.Remaining_Day;
          if (this.Remaining_Day_Noti <= 0) {
            this.Remaining_Day_Noti_msg = 'Your LGD Subscription expired.';
          }else if (this.Remaining_Day_Noti == 1) {
            this.Remaining_Day_Noti_msg = 'Your LGD Subscription expires in ' + this.Remaining_Day_Noti + ' Day.';
          } else {
            this.Remaining_Day_Noti_msg = 'Your LGD Subscription expires in ' + this.Remaining_Day_Noti + ' Days.';
          }
  
        }else{
          $("body").removeClass("main-notification-show");
        }
      });
    }
    component.NotificationList(1);
if(this.UserType!='6'){
  this.PlanPrice = '';
  var Form = new FormData();
  var link = global.service_url + global.GetMemberPlan;
  Form.append('KEY', global.key);
  this.http.post(link, Form).subscribe(data => {
    this.PlanData = data;
    this.GetPlanPrice(this.Is_MemberType, '1', this.PlanData);
    if (this.Is_MemberType == '0') {
      this.GetPlanPrice(this.Is_MemberType, '1', this.PlanData);
      $('#buyerDiv').click();
    } else if (this.Is_MemberType == '1') {
      this.GetPlanPrice(this.Is_MemberType, '1', this.PlanData);
      $('#sellerDiv').click();
    }
  });
  var Formp = new FormData();
  var link = global.service_url + global.GetMemberPalnHistory;
  Formp.append('KEY', global.key);
  Formp.append('User_Id', this.itemdata.User_Id);
  this.http.post(link, Formp).subscribe(data => {
    this.result=data;
    if(this.result.status==1){
      this.MemberPalnHistory = data['message'];
    }else if(this.result.status==0){
      this.MemberPalnHistory = this.result.status;
    }
  });
  this.Is_Payment_Alert = localStorage.getItem('Is_Payment_Alert');
  this.Is_Expired = localStorage.getItem('Is_Expired');
  if (this.Is_Payment_Alert == '1' || this.Is_Expired == '1') {
    if ((document.cookie && document.cookie.indexOf('IsPaymentClose'+this.itemdata.User_Id+'=Yes') != -1) || document.cookie.indexOf('Is_Checkbox_Payment_Show'+this.itemdata.User_Id+'=Yes') != -1 ) {
      $('#payment-modal').modal('hide');
    } else {
      if(this.Is_Expired == '0'){
        this.Is_Cancel='0';
      }else{
        this.Is_Cancel='1';
      }
      $('#payment-modal').modal('show');
    }
    
  }


  var ses_User_Id = this.itemdata.User_Id;
  var Is_Payment_Alert = this.Is_Payment_Alert;
  var Is_Expired = this.Is_Expired;
  window.onkeydown = function (e) {
    if (e.keyCode === 27) {
      if (Is_Payment_Alert == '1' || Is_Expired == '1') {
        if ((document.cookie && document.cookie.indexOf('IsPaymentClose=Yes') != -1) || document.cookie.indexOf('Is_Checkbox_Payment_Show' + ses_User_Id + '=Yes') != -1) {
          $('#payment-modal').modal('hide');
        } else {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/login';
          
        }
      }
    }
  };
  $('#checkpay').on('change', function () {
    var favorite = [];
    $.each($("input[name='selectedPay']:checked"), function(){            
        favorite.push($(this).val()); 
    });
    component.Is_Checkbox_Payment_Show(favorite);    
  });
  var NotiCountForm = new FormData();
  NotiCountForm.append('User_Id', this.itemdata.User_Id);
  NotiCountForm.append('KEY', global.key);
  var countLinnk = global.service_url + global.notificationCount;
  this.http.post(countLinnk, NotiCountForm).subscribe(data => {

    if (data['status'] == 1) {
      if (data['counts'] < 9) {
        this.notificationCounts = data['counts'];
      }
      else {
        this.notificationCounts = '9+';
      }
    }
  });
  if (this.itemdata.User_Type == "1") {
    this.SideStockupload = 'hide-class';
    this.SideInquiryMaster = 'hide-class';
    this.SideWishliastMaster = 'hide-class';
    this.SideMyaccountupload = 'hide-class';
    this.SideEnquiryMaster = 'hide-class';
    this.InventoryDisplay = 'hide-class';
  } else if (this.itemdata.User_Type == "2") {
    this.SidePlanMaster = 'hide-class';
    this.SideParameterMaster = 'hide-class';
    // this.SideInquiryMaster='hide-class';
    // this.SideWishliastMaster = 'hide-class';
    this.SideMemberMaster = 'hide-class';
    this.SideUserMaster = 'hide-class';

  } else if (this.itemdata.User_Type == "3") {
    this.SidePlanMaster = 'hide-class';
    this.SideParameterMaster = 'hide-class';
    // this.SideInquiryMaster='hide-class';
    // this.SideWishliastMaster = 'hide-class';
    this.SideMemberMaster = 'hide-class';
    this.SideUserMaster = 'hide-class';
  } else if (this.itemdata.User_Type == "4") {
    // this.SideMyaccountupload='hide-class';
    this.SidePlanMaster = 'hide-class';
    this.SideParameterMaster = 'hide-class';
    this.SideMemberMaster = 'hide-class';
    this.SideUserMaster = 'hide-class';
    this.SideStockupload = 'hide-class';
    // this.SideEnquiryMaster = 'hide-class';
    this.InventoryDisplay = 'hide-class';
  }
}
   
    this.Login_Name = this.itemdata.First_Name;
    window.onbeforeunload = function (e) {
      window.onunload = function () {
        window.localStorage.isMySessionActive = "false";
      }
      return undefined;
    };
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
      $('body').toggleClass("mobile-menu-fix");
    });
    $("#menu-toggle-2").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled-2");
      // $('#menu ul').hide();
    });
    $(".header-right ul li").on("click", function (e) {
      var Is_Active = $(".header-right ul li").attr('class');
      if (Is_Active == 'active') {
        $(".header-right ul li.active").removeClass("active");
      } else {
        $(this).addClass("active");
      }
      e.stopPropagation()
    });
    $(document).on("click", function (e) {
      $(".header-right ul li.active").removeClass("active");
    });



  var modal_counter = 0;
  $(document).ready(function () {
      $('.modal').on('shown.bs.modal', function () {
          modal_counter++;
          $('body').addClass('modal-open');
      });
      $('.modal').on('hidden.bs.modal', function () {
          modal_counter--;
          if (modal_counter) {
              $('body').addClass('modal-open');
          }
          else {
              $('body').removeClass('modal-open');
          }
      });
      $('.header-center-tabs ul li').click(function () {
          var tab_id = $(this).attr('data-tab');
          $('.header-center-tabs ul li').removeClass('current');
          $('.tbs-content').removeClass('current');
          $(this).addClass('current');
          $("#" + tab_id).addClass('current');
      });

  })
  
  
    $(document).ready(function () {
      $('.shape-img').on('click', function () {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
        } else {
          $(this).addClass('active');
        }
      });
      $('ul.color-bx-main li').on('click', function () {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
        } else {
          $(this).addClass('active');
        }
      });
      $('.save-searc-btn').on('click', function () {
        $('body').addClass('save-src-pop-open')
      });
      $('.save-src-close').on('click', function () {
        $('body').removeClass('save-src-pop-open')
      });
      $('.recent-searc-btn').on('click', function () {
        $('body').addClass('recent-src-pop-open')
      });
      $('.save-src-close').on('click', function () {
        $('body').removeClass('recent-src-pop-open')
      });
    });
  }
  Check_Is_Expired(days){
    if(days > 10){
      localStorage.removeItem('Is_Expired');
      localStorage.removeItem('Is_Payment_Alert');
    }
  }
  Main_Noti_Close() {
    var hours = 5;
    var now = new Date();
    var exp = new Date(now.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = 'Is_Main_Noti_Show'+this.itemdata.User_Id+'=Yes;expires='+exp.toUTCString();
    $("body").removeClass("main-notification-show");
  }

  Is_Checkbox_Payment_Show(IS_SHOW){
    if(this.itemdata.User_Type!='6'){
    if(IS_SHOW=='1'){
    var NotiForm = new FormData();
    NotiForm.append('User_Id', this.itemdata.User_Id);
    var link = global.service_url + global.CheckPaymentExpire;
    this.http.post(link, NotiForm).subscribe(data => {
      this.result = data['data'];
        if(this.result.Remaining_Day!=0){
          var days=this.result.Remaining_Day;
        var hours=2;
        var now = new Date();
        var exp = new Date(now.getTime() + (days*hours*60*60*1000));
        document.cookie = 'Is_Checkbox_Payment_Show'+this.itemdata.User_Id+'=Yes;expires='+exp.toUTCString();
        }
    });
      
    }else if(IS_SHOW==''){
      document.cookie = 'Is_Checkbox_Payment_Show'+this.itemdata.User_Id+'=Yes;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    
    }

  }

  }
  NotificationList1(){
    this.router.navigate(['/notification']);
  }

  NotificationList(page) {
    this.CurrentNotiPage = page;
    var NotiForm = new FormData();
    var ses_login = localStorage.getItem('loc_login');
    var logindate = JSON.parse(ses_login);
    NotiForm.append('User_Id', logindate.User_Id);
    NotiForm.append('PAGE', page);
    NotiForm.append('User_Number',  logindate.User_Number);
    NotiForm.append('User_Type', logindate.User_Type);
    if(logindate.User_Type!='6'){
    var link = global.service_url + global.getNotificationList;
    this.http.post(link, NotiForm).subscribe(data => {
      var result = data['data'];
      // this.pageNumber = data['totalPage'];
      // this.NotiListes = result;
      if (data['status'] == 1) {
        this.pageNumber = data['totalPage'];
        this.NotiListes = result;
      }else if (data['status'] == 0) {
					this.toastr.error(data['message']);
      }else if (data['status'] == 5) {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
      }

    });
    }
  }
  Planchoose(){
    this.Is_Model_Close=0;
    $('#payment-modal').modal('hide');
    $('#planchoose-modal').modal('show');
  }
  CheckForViews(data, counterVal, popupView, ReLink, ScreenNo) {
  ;
    this.toastr.clear();
    var descrese;
    $("#loader").show();
    if (counterVal == '9+') {
      descrese = '9+';
    } else if (counterVal == 0) {
      descrese = 0;
    } else {
        if(popupView!='false'){
          descrese = counterVal - 1;
        }else{
          descrese =counterVal;
        }
      
    }
    this.notificationCounts = descrese;
    var DoneNotiForm = new FormData();
    DoneNotiForm.append('push_id', data);
    DoneNotiForm.append('KEY', global.key);
    var DoneLink = global.service_url + global.notiReadDone;
    this.http.post(DoneLink, DoneNotiForm).subscribe(data => {
      this.result = data;
      this.response = data['response'];
      if (this.result.status == "1") {
        $("#loader").hide();
        this.GotoScreen(this.response, '');
      } else if (this.result.status == "0") {
        this.toastr.error(this.result.message);
        $("#loader").hide();
      }
    });
    $("#loader").hide();

  }

  ViewNotification(data) {

    $('.ShowNotification-popup').modal('show');
    // var SingleNotiForm = new FormData();

    // SingleNotiForm.append('push_id', User_Push_Id);
    // SingleNotiForm.append('KEY', global.key);
    // var postLink = global.service_url + global.NotificationDetail;

    // this.http.post(postLink, SingleNotiForm).subscribe(data => {
    //   if (data['status'] == 1) {
    $("#loader").hide();
    // var curr_result = data['data'];

    this.DetailTitle = data.Title;
    this.notiFicationRecord.emit(data.Title);

    this.DetailDesc = data.Description;
    this.notiFicationRecord.emit(data.Description);

    this.DetailShortDesc = data.Short_Description;
    this.notiFicationRecord.emit(data.Description);

    this.DetailImage = data.Image;
    this.notiFicationRecord.emit(data.Image);

    if (data.Video != '') {
      this.DetailVideo = this.sanitizer.bypassSecurityTrustResourceUrl(data.Video);
    } else {
      this.DetailVideo = '';
    }
    if (data.Button_Title == '') {
      data.Button_Title = 'Go';
    }
    else {
      this.DetailBtnTitle = data.Button_Title;
      this.notiFicationRecord.emit(data.Button_Title);
    }

    this.DetailScreenNo = data.Screen_No;
    this.notiFicationRecord.emit(data.Screen_No);

    this.DetailTransfer = data.Is_Redirect;
    this.notiFicationRecord.emit(data.Is_Redirect);
    // } else {
    //   this.toastr.success(data['message']);
    //   $("#loader").hide();
    // }
    // });

  }
  DeleteThis(Push_ids) {
    this.toastr.clear();
    $("#loader").show();
    var ses_login = localStorage.getItem('loc_login');
    var logindate = JSON.parse(ses_login);
    var deleteNotiForm = new FormData();
    deleteNotiForm.append('user_id', logindate.User_Id);
    deleteNotiForm.append('push_id', Push_ids);
    var DelLink = global.service_url + global.NotificationDelete;
    this.http.post(DelLink, deleteNotiForm).subscribe(data => {
      if (data['status'] == 1) {
        $("#loader").hide();
        $("#is_active_noti").addClass("active");
        this.ngOnInit();
        this.NotificationList(1);
      } else {
        $("#loader").hide();
        this.NotificationList(1);
      }
    });

  }

  GotoScreen(response, Sno) {


    if (Sno != '') {
      this.Screen_No = Sno;
      this.Is_Show_Detail = '0';
    } else if (response != '') {
      this.Screen_No = response.Screen_No;
      this.Is_Show_Detail = response.Is_Show_Detail;
    }
    if (this.Screen_No == '0') {
      if(response.Is_Redirect!=0) {
        if (this.Is_Show_Detail == '0') {
          this.loggout();
          window.open('', '_blank');
        } else if (this.Is_Show_Detail == '1') {
          this.ViewNotification(response);
        }
      }
    } else if (this.Screen_No == '1') {

      if (this.Is_Show_Detail == '0') {
        this.loggout();
        window.open('/login', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '6') {
      if (this.Is_Show_Detail == '0') {
        window.open('/myenquires', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '7') {
      if (this.Is_Show_Detail == '0') {
        window.open('/mywishlist', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '8') {
      if (this.Is_Show_Detail == '0') {
        window.open('/search-lgd', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }

    } else if (this.Screen_No == '10') {
      if (this.Is_Show_Detail == '0') {
        window.open('/search-lgd', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '11') {
      if (this.Is_Show_Detail == '0') {
        window.open('/My-Account', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '14') {
      if (this.Is_Show_Detail == '0') {
        window.open('/about-us', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '15') {
      if (this.Is_Show_Detail == '0') {
        window.open('/lgd-member-directory', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '16') {
      if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      } else {
        if (this.Is_Show_Detail == '1') {
          this.ViewNotification(response);
        } else {
          window.open(response.Link, '_blank');
        }

      }
    } else if (this.Screen_No == '17') {
      if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      } else {
        window.open(response.Link, '_blank');
      }
    } else if (this.Screen_No == '18') {

      if (this.Is_Show_Detail == '0') {
        window.open('/register', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }
    } else if (this.Screen_No == '19') {

      if (this.Is_Show_Detail == '0') {
        window.open('/sales-leads', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }

    }else if (this.Screen_No == '20') {
      if (this.Is_Show_Detail == '0') {
        window.open('/notification', '_blank');
      } else if (this.Is_Show_Detail == '1') {
        this.ViewNotification(response);
      }

    }
  }
  loggout() { 
    localStorage.clear();
    sessionStorage.clear();
    // location.reload();
  }
  Apply_Promo_Code(){
    $("#loader").show();
    if (this.itemdata.User_Type == '4' || this.itemdata.User_Type== '5') {
      this.UserType = '4';
    } else if (this.itemdata.User_Type == '2' || this.itemdata.User_Type == '3') {
      this.UserType = '2';
    }
    this.package_type = this.Time;
    var form = new FormData();
    // form.append("email_id", this.Email);
    form.append("package_type", this.package_type);
    form.append("plan_id", this.PlanId);
    form.append("coupon_code", this.Promo_Code);
    form.append("user_type", this.UserType);
    form.append('KEY', global.key);

    var link = global.service_url + global.ApplyCoupon;
    this.http.post(link, form).subscribe(data => {
      this.result = data;
      if (this.result.status == "1") {
        $("#loader").hide();
        $("#Promo_Code,#Promo_Code_Seller").prop("readonly", true);
        $("#Promo_Code_btn,#Promo_Code_btn_Seller").attr("disabled", "disabled");
        this.Offer_Id = this.result.Offer_Id;
        this.Offer_Amount = this.result.Offer_Amount;
        this.Promo_Code = this.Promo_Code;
        this.error_msg = this.result.message;

        this.promo_status = this.result.status;
      } else if (this.result.status == "0") {
        $("#loader").hide();
        this.error_msg = this.result.message;
        this.Offer_Id = '';
        this.Offer_Amount ='';
        this.Promo_Code = '';
        this.promo_status = this.result.status;
      }
    });

  }
  PaymentNow() {
    this.toastr.clear();
    var Plan_Id = this.PlanId;
    var User_Id = this.itemdata.User_Id;
    var Plan_Type = this.Time;
    var pForm = new FormData();
    pForm.append('Plan_Id', Plan_Id);
    pForm.append('User_Id', User_Id);
    pForm.append('Plan_Type', Plan_Type);
    pForm.append("Offer_Id", this.Offer_Id);
    pForm.append("Offer_Amount", this.Offer_Amount);
    pForm.append("coupon_code", this.Promo_Code);
    pForm.append('KEY', global.key);
    var DoneLink = global.service_url + global.RenewSubscription;
    this.http.post(DoneLink, pForm).subscribe(data => {
      this.result = data;
      this.response = data['response'];
      if (this.result.status == "1") {
        $("#loader").hide();
        var url = this.result.PaymentUrl;
        window.location.href = url;
      } else if (this.result.status == "0") {
        this.toastr.error(this.result.message);
        $("#loader").hide();
      }
    });
  }
  PaymentCancel1() {
    var loc_login = localStorage.getItem('loc_login');
    this.itemdata = JSON.parse(loc_login);
    var hours = 5;
    var now = new Date();
    var exp = new Date(now.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = 'IsPaymentClose'+this.itemdata.User_Id+'=Yes;expires='+exp.toUTCString();
    // document.cookie = 'IsPaymentClose=Yes; expires=' + exp.toUTCString();
    $('#payment-modal').modal('hide');
   
  }

  PaymentCancel() {
    localStorage.clear();
    sessionStorage.clear();
    // var hours = 5;
    // var now = new Date();
    // var exp = new Date(now.getTime() + (hours * 60 * 60 * 1000));
    // document.cookie = 'IsPaymentClose=Yes; expires=' + exp.toUTCString();
    $('#payment-modal').modal('hide');
    this.router.navigate(['/login']);
  }
  GetPlanPrice(TYPE, TIME, PlanData) {
    this.Offer_Id ='';
    this.Offer_Amount = '';
    this.Promo_Code = '';
    this.error_msg = '';

    $("#Promo_Code_btn").removeAttr("disabled", "disabled");
    $("#Promo_Code").prop("readonly", false);
    $("#Promo_Code").removeClass('form-control ng-dirty ng-touched ng-untouched ng-pristine ng-invalid');
    $("#Promo_Code").addClass('form-control ng-untouched ng-pristine ng-invalid');
    this.Time = TIME;
    this.PlanPrice = '';
    if (TIME == '1' && TYPE == '0') {
      if (PlanData.data.BuyerOneMonth != null) {
        this.time = '1 Month';
        var sign = '$ ';
        this.PlanId = PlanData.data.BuyerOneMonth.Plan_Id;
        this.PlanAmount = PlanData.data.BuyerOneMonth.Price;
        this.PlanPrice = sign + PlanData.data.BuyerOneMonth.Price;
      } else {
        this.time = 'Free';
        var sign = '';
        this.PlanId = 0;
        this.PlanAmount = 0;
        this.PlanPrice = this.time;
      }
    } else if (TIME == '12' && TYPE == '0') {
      if (PlanData.data.BuyerOneYear != null) {
        this.time = '12 Month';
        var sign = '$ ';
        this.PlanId = PlanData.data.BuyerOneYear.Plan_Id;
        this.PlanAmount = PlanData.data.BuyerOneMonth.Price;
        this.PlanPrice = sign + PlanData.data.BuyerOneYear.Price;
      } else {
        this.time = 'Free';
        var sign = '';
        this.PlanId = 0;
        this.PlanAmount = 0;
        this.PlanPrice = this.time;
      }
    } else if (TIME == '1' && TYPE == '1') {
      if (PlanData.data.SellerOneMonth != null) {
        this.time = '1 Month';
        var sign = '$ ';
        this.PlanId = PlanData.data.SellerOneMonth.Plan_Id;
        this.PlanAmount = PlanData.data.SellerOneMonth.Price;
        this.PlanPrice = sign + PlanData.data.SellerOneMonth.Price;
      } else {
        this.time = 'Free';
        var sign = '';
        this.PlanId = 0;
        this.PlanAmount = 0;
        this.PlanPrice = this.time;
      }
    } else if (TIME == '12' && TYPE == '1') {
      if (PlanData.data.SellerOneYear != null) {
        this.time = '12 Month';
        var sign = '$ ';
        this.PlanId = PlanData.data.SellerOneYear.Plan_Id;
        this.PlanAmount = PlanData.data.SellerOneYear.Price;
        this.PlanPrice = sign + PlanData.data.SellerOneYear.Price;
      } else {
        this.time = 'Free';
        var sign = '';
        this.PlanId = 0;
        this.PlanAmount = 0;
        this.PlanPrice = this.time;
      }
    }
  }
}
