import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { global } from '../../global';
import { Title } from '@angular/platform-browser';

import * as $ from 'jquery';
@Component({

  styleUrls: [
    './../../../assets/css/bootstrap.min.css',
    './../../../assets/css/select2.min.css',
    './../../../assets/css/jquery.dataTables.min.css',
    './../../../assets/css/MyCustome.css',
    './../../../assets/css/daterangepicker.css',
    './../../../assets/css/custom.css',
    './../../../assets/css/style_back.css',
  ],
  encapsulation: ViewEncapsulation.None,
  // selector: 'app-login',
  templateUrl: './forgot-password.component.html',
  // styleUrls: ['./login.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  private EmailForm: FormGroup;
  Email = "";
  submitted = false;
  result: any;
  key: any;
  User_Id:any;
  Forgot_BG: any;
  constructor(titleService:Title,private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private toastr: ToastrService)
  {
    var Form = new FormData();
    var link = global.service_url + global.Select_LGD_Settings_Background;
    Form.append('Setting_Key','forgot_password');
    Form.append('KEY', global.key);
    this.http.post(link, Form).subscribe(data => {
      this.result = data;
      if (this.result.status == "1") {
        this.Forgot_BG = this.result.message;
        $('.login-left').css('background-image', 'url(' + this.Forgot_BG + ')');
      }else if (this.result.status == "0") {

      }
    });

    this.EmailForm = this.formBuilder.group({
      Email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
     
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title = this.getTitle(router.routerState, router.routerState.root).join(' ');
        title = title.split(",").join("");
        titleService.setTitle(title);
      }
    });

  }

  get f() { return this.EmailForm.controls; }

  ngOnInit() {

    
    var ses_login = localStorage.getItem('ses_login');
    if (ses_login != null) {
      this.router.navigate(['/']);
    }
    $(window).resize(function() {
        $('.login-main').height($(window).height() - 0);
    });
    $("#Email").keyup(function(event) {
      if (event.keyCode === 13) {
        $("#loginbtn").click();
      }
    });
    $(window).trigger('resize');

    $(document).ready(function() {
      var formInputs = $('input[type="email"]');
      $(formInputs).parent().children('p.formLabel').addClass('formTop');
      $('.formWrapper').addClass('darken-bg');
    });
  }
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  check_email() {
    this.toastr.clear();
    $("#loader").show();
    this.submitted = true;
    var EmailForm = new FormData();
    // sessionStorage.removeItem("ses_verifyCode");

    if (this.Email != '') {
      EmailForm.append("Email", this.Email);
      EmailForm.append('KEY', global.key);
      var link = global.controller_service_Url + global.ForgotPassword_verifyEmail;
      this.http.post(link, EmailForm)
        .subscribe(data => {
          this.result = data;
          if (this.result.status == "1") {
            $("#loader").hide();
            this.toastr.warning(this.result.message);
            setTimeout(function () {
              location.reload();
            }, 2000);

          } else if (this.result.status == "0") {
            $("#loader").hide();
            this.toastr.error(this.result.message);
          }
        }
        );
    } else {
      $("#loader").hide();
      this.toastr.error('Please Enter Email.');
    }
  }
}
