import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppsComponent } from './views/apps/apps.component';
import { LoginComponent } from './views/login/login.component';
import { IndexComponent } from './views/index/index.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { CreateNewForgotPasswordComponent } from './views/create-new-forgot-password/create-new-forgot-password.component';
import { DefaultLayoutComponent } from './containers';
import { DefaultfrontLayoutComponent } from './frontcontainers';

export const routes: Routes = [
  
  {
    path: 'apps',
    component: AppsComponent,
    data: {title: "LGD Trade | Apps"},
  },
  // {
  //   path: '',
  //   component: LoginComponent,
  //   data: {title: "Login to your LGD Trade Account | Sign In"},
  // },
  {
    path: 'login',
    component: LoginComponent,
    data: {title: "Login to your LGD Trade Account | Sign In"},
  },
  {
    path: 'lab-grown-diamonds',
    loadChildren: () => import('./views/lab-grown-diamonds/lab-grown-diamonds.module').then(m => m.LabGrownDiamondsModule)
  },

  {
    path: 'buyer',
    loadChildren: () => import('./views/buyer/buyer.module').then(m => m.BuyerModule)
  },
  {
    path: 'seller',
    loadChildren: () => import('./views/seller/seller.module').then(m => m.SellerModule)
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {title: "LGD Trade | Forgot Password"}, 
  },
  {
    path: 'create-new-forgot-password',
    component: CreateNewForgotPasswordComponent,
    data: {title: "LGD Trade | Create New Password"},
  },
  {
    path: '',
    component: DefaultfrontLayoutComponent,
    children: [
      {
        path: '',
        component: IndexComponent,
        data: {title: "Lab Grown Diamond Marketplace for Synthetic Diamond Merchant"},
      },
      {
        path: 'about-us',
        loadChildren: () => import('./views/aboutus/aboutus.module').then(m => m.AboutusModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./views/registerwithus/registerwithus.module').then(m => m.RegisterwithusModule)
      },
      {
        path: 'success',
        loadChildren: () => import('./views/success/success.module').then(m => m.SuccessModule),
      },
      {
        path: 'unsubscribe',
        loadChildren: () => import('./views/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
      },
      {
        path: 'diamond-4c',
        loadChildren: () => import('./views/diamond-4c/diamond-4c.module').then(m => m.Diamond4cModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./views/contactus/contactus.module').then(m => m.ContactusModule)
      },
      {
        path: 'why-lgd',
        loadChildren: () => import('./views/whylgd/whylgd.module').then(m => m.WhylgdModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./views/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule)
      },
      {
        path: 'education',
        loadChildren: () => import('./views/education/education.module').then(m => m.EducationModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./views/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'terms-conditions',
        loadChildren: () => import('./views/termsconditions/termsconditions.module').then(m => m.TermsconditionsModule)
      },
      {
        path: 'process',
        loadChildren: () => import('./views/process/process.module').then(m => m.ProcessModule)
      },
      {
        path: 'download-app',
        loadChildren: () => import('./views/downloadapp/downloadapp.module').then(m => m.DownloadappModule)
      },
      {
        path: 'developer-api',
        loadChildren: () => import('./views/developerapi/developerapi.module').then(m => m.DeveloperapiModule)
      },
    ]
  }, 
  
  
  {
    path: '',
    component: DefaultLayoutComponent,
    // data: {title: "LGD Trade |"},
    children: [
      {
        path: 'search-lgd',
        loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule)
      },
      {
        path: 'Stone-Result',
        loadChildren: () => import('./views/searchresult-master/searchresult-master.module').then(m => m.SearchresultMasterModule)
      },
      {
        path: 'My-Account',
        loadChildren: () => import('./views/myaccount-master/myaccount-master.module').then(m => m.MyaccountMasterModule)
      },
      {
        path: 'notification',
        loadChildren: () => import('./views/notification-list/notification-list.module').then(m => m.NotificationListModule)
      },
      {
        path: 'sales-leads',
        loadChildren: () => import('./views/enquiries-master/enquiries-master.module').then(m => m.EnquiriesMasterModule)
      },
      {
        path: 'lgd-member-directory', 
        loadChildren: () => import('./views/member-directory/member-directory.module').then(m => m.MemberDirectoryModule)
      },
     
      {
        path: 'changepassword',
        loadChildren: () => import('./views/changepassword/changepassword.module').then(m => m.ChangepasswordModule)
      },
      {
        path: 'myenquires',
        loadChildren: () => import('./views/myinquiry-master/myinquiry-master.module').then(m => m.MyinquiryMasterModule)
      },
      {
        path: 'stock-upload',
        loadChildren: () => import('./views/stock-upload/stock-upload.module').then(m => m.StockUploadModule)
      },
      
      {
        path: 'upload-summary',
        loadChildren: () => import('./views/upload-summary/upload-summary.module').then(m => m.UploadSummaryModule)
      },
      {
        path: 'uploadsuccess',
        loadChildren: () => import('./views/uploadsuccess/uploadsuccess.module').then(m => m.UploadSuccessModule)
      },
      {
        path: 'mywishlist',
        loadChildren: () => import('./views/mywishlist-master/mywishlist-master.module').then(m => m.MywishlistMasterModule)
      },
      {
        path: 'upload-history',
        loadChildren: () => import('./views/upload-history/upload-history.module').then(m => m.UploadHistoryModule)
      },
      {
        path: 'diamond-list',
        loadChildren: () => import('./views/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'media-upload-history',
        loadChildren: () => import('./views/media-upload-history/media-upload-history.module').then(m => m.MediaUploadHistoryModule)
      },
      {
        path: 'media-upload',
        loadChildren: () => import('./views/upload/upload.module').then(m => m.UploadModule)
      },
    ]
  },
  {
    path: '**',
    component: DefaultfrontLayoutComponent,
    loadChildren: () => import('./views/404/404.module').then(m => m.PagenotfoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
