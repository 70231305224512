import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router, NavigationEnd ,ActivatedRoute} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { global } from '../../global';
import { Title } from '@angular/platform-browser';
import * as $ from 'jquery';
@Component({
  styleUrls: [
    './../../../assets/css/bootstrap.min.css',
    './../../../assets/css/select2.min.css',
    './../../../assets/css/jquery.dataTables.min.css',
    './../../../assets/css/MyCustome.css',
    './../../../assets/css/daterangepicker.css',
    './../../../assets/css/custom.css',
    './../../../assets/css/style_back.css',
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './create-new-forgot-password.component.html',
})
export class CreateNewForgotPasswordComponent implements OnInit {

  private NewPasswordForm: FormGroup;
  User_Id:any;
  submitted = false;
  result: any;
  key: any;
  Password: any;
  PasswordAgain:any;
  Email_Log_Id:any;
  Is_Display=1;
  Confirm_Forgot_BG : any;

  constructor(private activatedRoute: ActivatedRoute,titleService:Title,private formBuilder: FormBuilder, private http: HttpClient, private router: Router, private toastr: ToastrService)
  {
    var Form = new FormData();
    var link = global.service_url + global.Select_LGD_Settings_Background;
    Form.append('Setting_Key','create_new_forgot_password');
    Form.append('KEY', global.key);
    this.http.post(link, Form).subscribe(data => {
      this.result = data;
      if (this.result.status == "1") {
        this.Confirm_Forgot_BG=this.result.message;
        $('.login-left').css('background-image', 'url(' + this.Confirm_Forgot_BG + ')');
      }else if (this.result.status == "0") {

      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.User_Id =atob(params['UJfgDfgDJfgID']);
      var chars = this.User_Id.split('|');
      this.User_Id=chars[1];
      this.Email_Log_Id=chars[0];
  });

  var NewForm = new FormData();
      NewForm.append("Email_Log_Id", this.Email_Log_Id);
      NewForm.append("User_Id", this.User_Id);
      var link = global.service_url + global.Check_Expire_Time;
      
      this.http.post(link, NewForm)
        .subscribe(data => {
          this.result = data;
          if (this.result.status == "0") {
            $("#loader").hide();
            this.toastr.error(this.result.message);
            this.Is_Display=0;
          }else if (this.result.status == "1") {
            $("#loader").hide();
            this.Is_Display=1;
          }
        }
        );

    this.NewPasswordForm = this.formBuilder.group({
      Password: ['', Validators.required],
      PasswordAgain: ['', Validators.required]
     
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title = this.getTitle(router.routerState, router.routerState.root).join(' ');
        title = title.split(",").join("");
        titleService.setTitle(title);
      }
    });

  }
  get f() { return this.NewPasswordForm.controls; }

  ngOnInit() {
    var ses_login = sessionStorage.getItem('ses_login');
    if (ses_login != null) {
      this.router.navigate(['']);
    }
    $(window).resize(function() {
        $('.login-main').height($(window).height() - 0);
    });
    $("#PasswordAgain").keyup(function(event) {
      if (event.keyCode === 13) {
        $("#loginbtn").click();
      }
    });
    $(window).trigger('resize');

    $(document).ready(function() {
      var formInputs = $('input[type="email"],input[type="password"]');
      $(formInputs).parent().children('p.formLabel').addClass('formTop');
      $('.formWrapper').addClass('darken-bg');
    });
  }
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  forgot_Password() {
    this.toastr.clear();
    $("#loader").show();
    this.submitted = true;
    var NewPasswordForm = new FormData();
    if (this.Password == this.PasswordAgain) {
    if (this.Password != '' && this.PasswordAgain != '') {
      NewPasswordForm.append("Password", this.Password);
      NewPasswordForm.append("User_Id", this.User_Id);
      NewPasswordForm.append("Email_Log_Id", this.Email_Log_Id);
      NewPasswordForm.append('KEY', global.key);

      var link = global.service_url + global.Forgottpassword;
      this.http.post(link, NewPasswordForm)
        .subscribe(data => {
          this.result = data;
          if (this.result.status == "1") {
            $("#loader").hide();
            this.toastr.success(this.result.message);
            setTimeout(()=>{ 
              this.router.navigate(['/login']);
         }, 1000);
          } else if (this.result.status == "0") {
            $("#loader").hide();
            this.toastr.error(this.result.message);
          }else if (this.result.status == "2") {
            $("#loader").hide();
            this.toastr.error(this.result.message);
          }
        }
        );
    } else {
      $("#loader").hide();
      this.toastr.error('Please Fill Required Filed .');
    }
  } else {
    $("#loader").hide();
    this.toastr.error('Your passwords do not match!');
  }
  }
}
